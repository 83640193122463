<template>
  <Header />
  <Content />
  <div v-if="!global.user.hasOwnProperty('id') && currentRoute.path != '/login'" class="container is-max-desktop">
    <h1>Login for medlemmer</h1>
    <LoginButtons />
  </div>

  <div v-if="global.user.hasOwnProperty('id')" class="container is-max-desktop">
    <h1>Du er logget ind</h1>
    <p>Åbner bookingsystemet...</p>
  </div>

  <form v-if="currentRoute.path == '/login'" class="container is-max-desktop">
    <h1>Login med mail + kode</h1>
    <p>Denne form for login er i test-version. Hvis du vil teste, så skriv til info@martinrud.dk</p>

    <div class="form-section max-width">
      <div class="field">
        <div class="control">
          <label for="email">E-mail</label>
          <input v-model="email" class="input" type="email" id="email" required>
        </div>
      </div>
      <div class="field">
        <div class="control">
          <label for="password">Adgangskode</label>
          <input v-model="password" class="input" type="password" id="password" required>
        </div>
      </div>
      <button class="button is-success" @click="login()">Log ind</button>
    </div>
  </form>

  <Footer />
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import LoginButtons from '@/components/LoginButtons.vue'
import Footer from '@/components/Footer.vue'
import Content from '@/components/Content.vue'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    return {
      global,
      apiRoot: process.env.VUE_APP_API_ROOT
    }
  },
  data() {
    return {
      email: null,
      password: null
    }
  },
  created() {
    //this.checkLogin(),

    setTimeout(function (scope) {
      if (scope.global.user.hasOwnProperty('attentionBookings')) {
        console.log('pushing....');
        let url = scope.global.user.attentionBookings.length == 0 ? '/kalender' : '/mine-bookinger';
        scope.$router.push(url);
      }
    }, 500, this);
  },
  methods: {
    login() {

      axios.get(process.env.VUE_APP_API_ROOT + '/loginUserPass.php?email=' + this.email + '&password=' + this.password)
        .then(response => {
          console.log(response.data);
        });
    },
    checkLogin() {
      axios.get(process.env.VUE_APP_API_ROOT + '/test.php')
        .then(response => {
          console.log(response.data);
          //this.$router.push('/booking/' + this.booking.id + '?new=1');

        });
    }
  },
  components: {
    Header,
    LoginButtons,
    Footer,
    Content
  },
  computed: {
    currentRoute() {
      return this.$route;
    }
  }
})
</script>
