<template>
    <div v-if="content.hasOwnProperty('id')" class="container is-max-desktop">
        <h1>{{ content.headline != '' ? content.headline : content.name }}</h1>
        <div v-html="content.abstract" class="abstract"></div>
        <div v-html="content.text" class="text"></div>
    </div>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

export default defineComponent({
    setup() {
        const { global } = useComposition();

        return {
            global
        }
    },
    data() {
        return {
            content: {}
        }
    },
    created() {
        if (this.$route.path != '/login') {
            this.contentLoad();
        }

    },
    methods: {
        contentLoad() {
            let path = this.$route.path == '/' || this.$route.path.startsWith("/invite") ? '?altTemplate=page' : this.$route.path;
            axios.get('https://carshare.martinrud.dk' + path)
                .then(response => {
                    this.content = response.data;
                    document.title = this.content.browserTitle;
                    document.querySelector('meta[name="description"]').setAttribute("content", this.content.metaDescription);
                })
        }
    },
    computed: {
        currentRoute() {
            return this.$route;
        }
    }
})
</script>